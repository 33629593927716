<template>
  <div class="signIn-page container-fluid">
    <div class="container">
      <div class="account-box">
        <span class="forex">Smart Forex</span>
        <span class="account-login">Account Login</span>
      </div>
      <div class="account-form">
        <form class="form" @submit.prevent="submit">
          <div class="form-group col-md-12 col-sm-12 email">
            <input
              v-model="form.email"
              type="email"
              placeholder="Email*"
              required
            />
          </div>
          <div class="form-group col-md-12 col-sm-12 password">
            <input
              v-model="form.password"
              type="password"
              placeholder="Password*"
              required
            />
          </div>
          <div class="form-group col-md-12 col-sm-12 button-login">
            <button type="submit">Log in</button>
          </div>

          <div class="information-block">
            <div class="link">
              <router-link :to="{ name: 'forgot-password' }"
                >Forgot password ?</router-link
              >
            </div>
            <div class="link">
              <router-link :to="{ name: 'sign-up' }">Register now</router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    form: {
      email: '',
      password: '',
    },
  }),

  methods: {
    submit() {
      this.signIn(this.form)
        .then(() => {
          this.$router.push({ name: 'account' })
        })
        .catch(err => {
          console.warn(err)
        })
    },

    ...mapActions('User', ['signIn']),
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
